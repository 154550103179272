<template>
  <v-row>
    <v-col cols="12" :md="isRange ? '6' : '12'">
      <v-text-field
        v-model="rangeStart"
        color="indigo darken-4"
        :label="`${label} ${isRange ? '( From )' : ''}`"
        ref="rangeStart"
        v-bind="{ ...$props, ...$attrs }"
        @input="updateTrigger"
      />
    </v-col>
    <v-col v-if="isRange" cols="12" :md="isRange ? '6' : '12'">
      <v-text-field
        v-model="rangeEnd"
        color="indigo darken-4"
        :label="`${label} ${isRange ? '( To )' : ''}`"
        ref="rangeEnd"
        v-bind="{ ...$props, ...$attrs }"
        @input="updateTrigger"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  model: {
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: null
    },
    range: {
      type: Object,
      default: null
    },
    selectType: {
      type: String,
      default: "integerRange"
    }
  },
  data() {
    return {
      rangeStart: "",
      rangeEnd: ""
    };
  },
  computed: {
    isRange() {
      return ["integerRange", "dataIntegerRange"].includes(this.selectType);
    },
    isInteger() {
      return this.selectType == "integer";
    }
  },
  components: {
  },
  methods: {
    updateTrigger() {
      if (this.isRange) {
        if (this.rangeStart && this.rangeEnd) {
          this.$emit("change", this.rangeStart + " ~ " + this.rangeEnd);
        }
      } else {
        this.$emit("change", this.rangeStart);
      }
    }
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.field-group input {
  width: 100%;
}
</style>
